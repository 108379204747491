import React from 'react';
import { Helmet } from 'react-helmet';

import Header from 'sections/ScreverOcxProgram/Header';
import Hero from 'sections/ScreverBtqProgram/Hero';
import PagesList from 'sections/ScreverOcxProgram/PagesList';

import 'sections/ScreverOcxProgram/common.scss';

const PAGES = [
  {
    title: 'Program',
    url: '/events/screver-btq-program/program',
  },
  {
    title: 'Cinema',
    url: '/events/screver-btq-program/cinema',
  },
  {
    title: 'Hackathons',
    url: '/events/screver-btq-program/vote',
  },
  {
    title: '✌🏻feedback',
    url: '/events/screver-btq-program/feedback',
  },
];

const ScreverOcxProgram = () => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title="Screver BTQ Event | Agenda"
      >
        <meta name="description" content="" />
        <meta name="theme-color" content="#151517"></meta>

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Epilogue:wght@400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <div className="scr-ocx-program scr-btq-event">
        <Header />

        <main>
          <Hero />
          <PagesList linksToPages={PAGES} />
        </main>
      </div>
    </>
  );
};

export default ScreverOcxProgram;
